import { useTranslation } from "react-i18next";
import "./Valves.css";
import { useState } from "react";

const Valves = () => {

    const { t } = useTranslation();

    const qualitiesFReg = [
        t("Valves 10 years of experience"),
        t("Valves Installed systems"),
        t("Valves Tailor-made solutions"),
        t("Valves Competitions"),
    ];
    const valvesImages = [
        t("F-Reg Street"),
        t("F-Reg Canal"),
        t("F-Reg How"),
        t("F-Reg Information"),
    ];
    const heroImgPath = "./images/F-Reg_VHA_001.png";

    const valvesRetentionList = [
        t("F-Reg Retention li 1"),
        t("F-Reg Retention li 2"),
        t("F-Reg Retention li 3"),
        t("F-Reg Retention li 4")
    ];

    return (<div id="valves-page">
        <div id="valves-info">
            <div id="valves-introduction">
                <div id="valves-title-hero">
                    <div id="valves-title-hero-text">
                        <h1>{t("Valves title")}</h1>
                        <div id="valves-company-introduction">{t("F-Reg introduction") + ":"}</div>
                        <div>{qualitiesFReg.map((qual, k) => <div key={k} className="valves-company-quality">{qual}</div>)}</div>
                    </div>
                    <img alt="" src={heroImgPath} />
                </div>
                <div id="valves-story">
                    <h2>{t("F-Reg Climate change")}</h2>
                    <p>{t("F-Reg Climate change description")}</p>
                    <h2>{t("F-Reg Sewage condition")}</h2>
                    <p>{t("F-Reg Sewage condition description")}</p>
                    <h2>{t("F-Reg Regulations")}</h2>
                    <p>{t("F-Reg Regulations description")}</p>
                    <h2>{t("F-Reg High urbanization")}</h2>
                    <p>{t("F-Reg High urbanization description")}</p>
                </div>
            </div>
        </div>
        <div id="valves-explanation">
            <div id="valves-list">
                {t("F-Reg Retention")}
                <ul>
                    {valvesRetentionList.map((vList, k) => <li key={k}><div dangerouslySetInnerHTML={{ __html: vList }} ></div></li>)}
                </ul>
            </div>
            {valvesImages.map((img, k) => img.length !== 0 && <img key={k} alt="" src={img} />)}
        </div>
        <Explorer trans={t} />
    </div>);
}

const Explorer = ({ trans }) => {

    const valvesFolderPath = "./assets/images/valves/";
    const files = getFilesFromFolder(trans, valvesFolderPath);
    const temporary_solution = true;
    const [selectedFile, setSelected] = useState({ selectedId: 0, selectedFolder: 1 });



    return (<div id="explorer-container">
        <h2>{trans("Valves Gallery and brochures")}</h2>
        <div id="explorer-container-content">
            <div id="explorer-container-folders">{files.map((file, k) => {
                if (isFolder(file)) {
                    //
                    return (<div></div>);
                } else {
                    //
                    if (temporary_solution) {
                        return (trans(`F-Reg General info file`).length > 0 || k > 0) ? (<div key={k} className="explorer-folder">
                            <div className="explorer-folder-title">{file && file.name}</div>
                            {file && file.files.map((f, l) => <div key={l} className={"explorer-folder-file" + (l === selectedFile.selectedId && k === selectedFile.selectedFolder ? " presented-file" : "")} onClick={() => handleFileClick(l, k, setSelected)}>{f && f.name}</div>)}
                        </div>) : <div></div>;
                    }
                    return (<div></div>);
                }
            })} { }</div>
            <div id="explorer-container-presenter">
                <img alt="" src={files[selectedFile.selectedFolder] && files[selectedFile.selectedFolder].files[selectedFile.selectedId].address} />
                { }
                {trans(`F-Reg ${files[selectedFile.selectedFolder].name !== trans("F-Reg General files") ? files[selectedFile.selectedFolder].name : "General"} info file`).length > 0 && <a id="explorer-container-attachment" href={trans(`F-Reg ${files[selectedFile.selectedFolder].name !== trans("F-Reg General files") ? files[selectedFile.selectedFolder].name : "General"} info file`)} className="btn">{trans("Download infosheet")}</a>}
            </div>
        </div>
    </div >)
}

/**
 * Function returning a file array from a specific directory on the server
 * For now the use case is really bare, handling only specified hard-written paths. In the future, might be changed to calling a script
 * on the server with the requested path and fetching the files from the returned paths.
 * 
 * @param {*} trans tlations
 * @param {String} path Path to directory, where the files should be located and read
 * @returns array of files
 */
const getFilesFromFolder = (trans, path) => {

    const fileArray = [];

    if (fileArray.length === 0) {
        const temporaryFilesArray = [
            {
                name: "SCADA", //trans("F-Reg General files"),
                files: [
                    // { name: trans("F-Reg How it works"), address: "./images/F-Reg_How.png" },
                    // { name: trans("F-Reg Additional information"), address: "./images/F-Reg_Informacje.png" },
                    { name: trans("F-Reg SCADA"), address: "./images/F-Reg_SCADA.png" },
                ],
                info_file: trans("F-Reg General info file")
            },
            {
                name: "VHA",
                files: [
                    { name: "F-Reg VHA 01", address: "./images/F-Reg_VHA_001.png" },
                    { name: "F-Reg VHA 02", address: "./images/F-Reg_VHA_002.png" },
                    { name: "F-Reg VHA 03", address: "./images/F-Reg_VHA_003.jpeg" },
                    { name: "F-Reg VHA 04", address: "./images/F-Reg_VHA_004.jpg" },
                    { name: "F-Reg VHA 05", address: "./images/F-Reg_VHA_005.jpg" },
                ],
                info_file: trans("F-Reg VHA info file")
            },
            {
                name: "PMD",
                files: [
                    { name: "F-Reg PMD 01", address: "./images/F-Reg_PMD_001.jpg" },
                    { name: "F-Reg PMD 02", address: "./images/F-Reg_PMD_002.jpg" },
                    { name: "F-Reg PMD 03", address: "./images/F-Reg_PMD_003.png" },
                    { name: "F-Reg PMD 04", address: "./images/F-Reg_PMD_004.png" },
                    { name: "F-Reg PMD Tujac", address: "./images/F-Reg_PMD_Tujac1.jpg" },
                ],
                info_file: trans("F-Reg PMD info file")
            }
        ];

        return temporaryFilesArray;
    }


    return fileArray;
}

const isFolder = () => {

    let isFolder = false;

    //

    return isFolder;
}

const handleFileClick = (fileId, folderId, setSelected) => {
    // console.log(file);
    setSelected({ selectedId: fileId, selectedFolder: folderId });
}

export { Valves };