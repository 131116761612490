import { useTranslation } from "react-i18next";
import "./Drones.css";
import "../assets/images/cld.png";
import { useEffect, useState, useRef } from "react";
import { useLoaderData } from "react-router-dom";
import {
    okzoomer,
    gestureToMatrix,
    getOrigin,
    applyMatrix
} from "../utils/Gestures";
import * as Three from "three";


const Drones = () => {

    const { t } = useTranslation();
    var show = true;
    const drones = { drones: getDrones({ t }), story: t("Drones story") };

    return (<div id="drones-page">
        <div id="drones-navigation">
            {drones.drones.map((drone, k) => <a key={k} href={"#" + drone.name} className="btn">{drone.name}</a>)}
        </div>
        {!show || <div id="drones-story">{drones.story}</div>}
        {drones.drones.map((drone, k) => <div key={k} className="drone" id={drone.name}>
            <h2 className="drone-name">{drone.name}</h2>
            <div className="drone-intro">
                <div className="drone-info">
                    <p>
                        {drone.info.short}
                    </p>
                    <ul>{drone.info.ul.map((el, m) => <li key={m}>{el}</li>)}</ul>
                    <p className="drone-info-additional">{drone.info.additional}</p>
                </div>
                <img alt={drone.name} className="drone-photo disable-visual-search" src={`./images/${drone.shortName.toLowerCase()}.png`} />
            </div>
            <a className="drone-data" href={drone.datasheet} style={drone.datasheet.length === 0 ? { display: "none" } : {}}>{t("Download data")}</a>
            {drone.tables.map((tab, l) => <table key={l} className="drone-table">
                <tbody>
                    <tr className="table-title"><th >{tab.title}</th><th /></tr>
                    {tab.rows.map((row, m) => <tr key={m}>
                        {row.columns.map((col, n) => n < 1
                            ?
                            <th className="table-header" style={row.columns.length === 1 ? { border: "none" } : {}} key={n}>{col.text}</th>
                            :
                            <td key={n} className="table-cell" style={{}}>{col.text}</td>)}
                    </tr>)}
                </tbody>
            </table>)}
            <div className="drone-results">
                <h3>{t("Inspection results")}</h3>
                <div id="results">
                    {/* {drone.results.map((result, k) => <img key={k} alt="" src={result} />)} */}
                    <Gallery text="" photos={drone.results} />
                </div>
            </div>
            <Gallery text={t("Photos")} photos={drone.images} />
            <Videos t={t} videos={drone.videos} dimensions={{ width: 0.9 * 800, height: 0.9 * 450 }} />
        </div>)}
    </div>);
}

const getDrones = ({ t }) => {
    const array = [
        {
            name: t("CLD-name"),
            shortName: "CLD",
            info: {
                short: t("CLD-info-short"),
                ul: [
                    t("CLD-info-ul-1"),
                    t("CLD-info-ul-2"),
                    t("CLD-info-ul-3")
                ],
                additional: t("CLD-additional")
            },
            datasheet: t("CLD-datasheet"),
            tables: [
                {
                    title: t("CLD-table1-title"),
                    rows: [
                        {
                            columns: [
                                {
                                    text: t("CLD-table1-col1_1")
                                },
                                {
                                    text: t("CLD-table1-col1_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CLD-table1-col2_1")
                                },
                                {
                                    text: t("CLD-table1-col2_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CLD-table1-col3_1")
                                },
                                {
                                    text: t("CLD-table1-col3_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CLD-table1-col4_1")
                                },
                                {
                                    text: t("CLD-table1-col4_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CLD-table1-col5_1")
                                },
                                {
                                    text: t("CLD-table1-col5_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CLD-table1-col6_1")
                                },
                                {
                                    text: t("CLD-table1-col6_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CLD-table1-col7_1")
                                },
                                {
                                    text: t("CLD-table1-col7_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CLD-table1-col8_1")
                                },
                                {
                                    text: t("CLD-table1-col8_2")
                                }
                            ]
                        }
                    ],
                },
                {
                    title: t("CLD-table2-title"),
                    rows: [
                        {
                            columns: [
                                {
                                    text: t("CLD-table2-col1_1")
                                },
                                {
                                    text: t("CLD-table2-col1_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CLD-table2-col2_1")
                                },
                                {
                                    text: t("CLD-table2-col2_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CLD-table2-col3_1")
                                },
                                {
                                    text: t("CLD-table2-col3_2")
                                }
                            ]
                        }
                    ]
                },
                {
                    title: t("CLD-table3-title"),
                    rows: [
                        {
                            columns: [
                                {
                                    text: t("CLD-table3-col1_1")
                                }
                            ]
                        }
                    ]
                },
                {
                    title: t("CLD-table4-title"),
                    rows: [
                        {
                            columns: [
                                {
                                    text: t("CLD-table4-col1_1")
                                },
                                {
                                    text: t("CLD-table4-col1_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CLD-table4-col2_1")
                                },
                                {
                                    text: t("CLD-table4-col2_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CLD-table4-col3_1")
                                },
                                {
                                    text: t("CLD-table4-col3_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CLD-table4-col4_1")
                                },
                                {
                                    text: t("CLD-table4-col4_2")
                                }
                            ]
                        }
                    ]
                },
                {
                    title: t("CLD-table5-title"),
                    rows: [
                        {
                            columns: [
                                {
                                    text: t("CLD-table5-col1_1")
                                },
                                {
                                    text: t("CLD-table5-col1_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CLD-table5-col2_1")
                                },
                                {
                                    text: t("CLD-table5-col2_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CLD-table5-col3_1")
                                },
                                {
                                    text: t("CLD-table5-col3_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CLD-table5-col4_1")
                                },
                                {
                                    text: t("CLD-table5-col4_2")
                                }
                            ]
                        },
                    ]
                }
            ],
            images: ["./images/cld-canal-long.png", "./images/cld-canal-intersection.png"],
            videos: [{ title: t("Canal inspection"), path: "./videos/Poznan_short.mp4" }],
            results: []
        },
        {
            name: t("CSR-name"),
            shortName: "CSR",
            info: {
                short: t("CSR-info-short"),
                ul: [],
                additional: ""
            },
            datasheet: t("CSR-datasheet"),
            tables: [
                {
                    title: t("CLD-table1-title"),
                    rows: [
                        {
                            columns: [
                                {
                                    text: t("CLD-table1-col1_1")
                                },
                                {
                                    text: t("CSR-table1-col1_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CLD-table1-col2_1")
                                },
                                {
                                    text: t("CSR-table1-col2_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CLD-table1-col3_1")
                                },
                                {
                                    text: t("CSR-table1-col3_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CLD-table1-col4_1")
                                },
                                {
                                    text: t("CSR-table1-col4_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CLD-table1-col5_1")
                                },
                                {
                                    text: t("CSR-table1-col5_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CLD-table1-col6_1")
                                },
                                {
                                    text: t("CSR-table1-col6_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CLD-table1-col7_1")
                                },
                                {
                                    text: t("CSR-table1-col7_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CLD-table1-col8_1")
                                },
                                {
                                    text: t("CSR-table1-col8_2")
                                }
                            ]
                        },
                    ]
                },
                {
                    title: t("CLD-table2-title"),
                    rows: [
                        {
                            columns: [
                                {
                                    text: t("CLD-table2-col1_1")
                                },
                                {
                                    text: t("CLD-table2-col1_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CLD-table2-col2_1")
                                },
                                {
                                    text: t("CLD-table2-col2_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CSR-table2-col3_1")
                                },
                                {
                                    text: t("CSR-table2-col3_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CSR-table2-col4_1")
                                },
                                {
                                    text: t("CSR-table2-col4_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CSR-table2-col5_1")
                                },
                                {
                                    text: t("CSR-table2-col5_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CSR-table2-col6_1")
                                },
                                {
                                    text: t("CSR-table2-col6_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CSR-table2-col7_1")
                                },
                                {
                                    text: t("CSR-table2-col7_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CSR-table2-col8_1")
                                },
                                {
                                    text: t("CSR-table2-col8_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CLD-table3-title")
                                },
                                {
                                    text: t("CSR-table2-col9_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CLD-table2-col3_1")
                                },
                                {
                                    text: t("CLD-table2-col3_2")
                                }
                            ]
                        },
                    ]
                },
                {
                    title: t("CLD-table5-title"),
                    rows: [
                        {
                            columns: [
                                {
                                    text: t("CLD-table5-col1_1")
                                },
                                {
                                    text: t("CLD-table5-col1_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CLD-table5-col2_1")
                                },
                                {
                                    text: t("CLD-table5-col2_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CLD-table5-col3_1")
                                },
                                {
                                    text: t("CLD-table5-col3_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CLD-table5-col4_1")
                                },
                                {
                                    text: t("CLD-table5-col4_2")
                                }
                            ]
                        },
                        {
                            columns: [
                                {
                                    text: t("CSR-table3-col5_1")
                                },
                                {
                                    text: t("CSR-table3-col5_2")
                                }
                            ]
                        }
                    ]
                }
            ],
            images: ["./images/cld-canal-long.png", "./images/cld-canal-intersection.png"],
            videos: [{ title: t("Canal inspection"), path: "./videos/Poznan_short.mp4" }],
            results: ["./images/cld-results-section.png", "./images/cld-results-long.png"]
        }
    ];

    return array;
}

const changeSlide = (number, state, func) => {
    var i = state.small.findIndex(i => i === state.main) + number;
    if (i < 0) i = state.small.length - 1;
    else if (i >= state.small.length) i = 0;
    const prev = state.small[i];

    func({ ...state, main: prev });
}

const Gallery = ({ text, photos }) => {
    const [popup, setPopup] = useState({ main: null, small: [] });
    const galleryRef = useRef(null), smallRef = useRef(null),
        prevRef = useRef(null), nextRef = useRef(null);

    useEffect(() => {
        const handleClick = (event) => {
            if ((galleryRef.current && !galleryRef.current.contains(event.target)) && (smallRef.current && !smallRef.current.contains(event.target)) &&
                (prevRef.current && !prevRef.current.contains(event.target)) && (nextRef.current && !nextRef.current.contains(event.target))) {
                setPopup({ main: null, small: [] });
            }
        };

        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, [galleryRef]);

    return (<div id="gallery">
        <h4>{text}</h4>
        <div id="gallery-photos">{photos.map((photo, k) => <div key={k}>
            <img className="drone-image" alt="" src={photo} onClick={() => setPopup({ main: photo, small: photos })} />
        </div>)}
        </div>
        {popup.main === null || <div id="gallery-popup">
            <div id="main" ref={galleryRef}>
                <img alt="" src={popup.main} />
            </div>
            <div id="small" ref={smallRef}>
                {popup.small.map((i, k) => <img key={k} alt="" className="gallery-small" src={i} onClick={() => {
                    // const temp = popup.small;
                    // temp
                    setPopup({ ...popup, main: i });
                }} />)}
            </div>
            <div className="prev" ref={prevRef} onClick={() => changeSlide(-1, popup, setPopup)}>&#10094;</div>
            <div className="next" ref={nextRef} onClick={() => changeSlide(1, popup, setPopup)}>&#10095;</div>
        </div>}
    </div >);
}
const Videos = ({ t, dimensions, videos }) => {
    const refContainer = useRef(null);
    const firstUpdate = useRef(false);
    const [playBtn, setPlay] = useState({ isPlaying: false, path: "./images/play-btn-img.png" });
    const video = videos.length !== 0 ? videos[0] : { title: "", path: "/fi" };
    const scene = new Three.Scene();
    const perspectiveCam = new Three.PerspectiveCamera(75, dimensions.width / dimensions.height, 1, 100);
    const renderer = new Three.WebGLRenderer();
    const sphere = new Three.SphereGeometry(15, 128, 64);
    const videoEl = document.createElement("video");
    videoEl.src = video.path;
    // const { video, videoEl, renderer } = useLoaderData();

    //setup();
    let origin;
    let initial_ctm = new DOMMatrix();
    let el = renderer.domElement;
    el.style.transformOrigin = "0 0";



    useEffect(() => {
        if (firstUpdate.current) {
            console.log("Inside video useEffect");

            refContainer.current && refContainer.current.appendChild(renderer.domElement);
            renderer.setSize(dimensions.width, dimensions.height);
            videoEl.id = "video-conte";
            videoEl.crossOrigin = "anonymous";
            const texture = new Three.VideoTexture(videoEl);
            const material = new Three.MeshBasicMaterial({ map: texture });
            material.side = Three.BackSide;

            const mesh = new Three.Mesh(sphere, material);

            scene.add(mesh);
            renderer.setAnimationLoop(() => renderer.render(scene, perspectiveCam));
            // zoom in / out     
            const clamp = (v, min, max) => Math.max(min, Math.min(v, max));
            renderer.domElement.addEventListener('wheel', e => {
                e.preventDefault();
                if (e.ctrlKey) {
                    perspectiveCam.fov = clamp(perspectiveCam.fov + e.deltaY, 10, 120);
                    // need to call this function after changing most of properties in PerspectiveCamera
                    perspectiveCam.updateProjectionMatrix();
                }
            }, { passive: false });

            // zooming on mobile

            if (!window.DOMMatrix) {
                if (window.WebKitCSSMatrix) {
                    window.DOMMatrix = window.WebKitCSSMatrix;
                } else {
                    throw new Error("Couldn't find a DOM Matrix implementation");
                }
            }

            let origin;
            let initial_ctm = new DOMMatrix();
            let el = renderer.domElement;
            el.style.transformOrigin = "0 0";

            okzoomer(document.querySelector(".video-content"), {
                startGesture(gesture) {
                    /*
                        Clear the element's transform so we can 
                        measure its original position wrt. the screen.

                        (We don't need to restore it because it gets 
                        overwritten by `applyMatrix()` anyways.)
                     */
                    el.style.transform = "";
                    origin = getOrigin(el, gesture);
                    applyMatrix(
                        el,
                        gestureToMatrix(gesture, origin).multiply(initial_ctm)
                    );
                },
                doGesture(gesture) {
                    applyMatrix(
                        el,
                        gestureToMatrix(gesture, origin).multiply(initial_ctm)
                    );
                },
                endGesture(gesture) {
                    initial_ctm = gestureToMatrix(gesture, origin).multiply(initial_ctm);
                    applyMatrix(el, initial_ctm);
                }
            });

            // rotate camera
            let mouseDown = false;
            renderer.domElement.addEventListener('mousedown', e => {
                if (e.button === 0) mouseDown = true;
            });

            // renderer.domElement.addEventListener('click', e => {
            //     if (videoEl.paused) {
            //         videoEl.play();
            //     } else {
            //         videoEl.pause();
            //     }
            // });

            window.addEventListener('mouseup', e => {
                if (e.button === 0) mouseDown = false;
            });

            window.addEventListener('mousemove', e => {
                if (!mouseDown) return;

                const { movementX, movementY } = e;

                // rotateX: rotate vertically since x-axis is horizontal
                const rotateX = movementY / 100;
                const rotateY = movementX / 100;

                perspectiveCam.rotateX(rotateX);
                perspectiveCam.rotateY(rotateY);
            });

            // return;

            okzoomer(document.querySelector(".video-content"), {
                startGesture(gesture) {
                    /*
                        Clear the element's transform so we can 
                        measure its original position wrt. the screen.
        
                        (We don't need to restore it because it gets 
                        overwritten by `applyMatrix()` anyways.)
                     */
                    el.style.transform = "";
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    origin = getOrigin(el, gesture);
                    applyMatrix(
                        el,
                        gestureToMatrix(gesture, origin).multiply(initial_ctm)
                    );
                },
                doGesture(gesture) {
                    applyMatrix(
                        el,
                        gestureToMatrix(gesture, origin).multiply(initial_ctm)
                    );
                },
                endGesture(gesture) {
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    initial_ctm = gestureToMatrix(gesture, origin).multiply(initial_ctm);
                    applyMatrix(el, initial_ctm);
                }
            });
        }

        firstUpdate.current = true;
        //document.getElementsByClassName("video").item(0).appendChild(renderer.domElement);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    return (<div className="video" style={video.path.length === 0 ? { display: "none" } : {}}>
        {/* <video className="video-content" style={video.path === "/" ? { display: "none" } : {}} src={video.path} controls/> */}
        <div className="video-content" ref={refContainer}></div>
        <div className={playBtn.isPlaying ? "play-pause fade" : "play-pause"} onClick={() => {
            if (videoEl.paused) {
                videoEl.play();
                setPlay({ isPlaying: true, path: "./images/pause-btn-img.png" });
            }
            else {
                videoEl.pause();
                setPlay({ isPlaying: false, path: "./images/play-btn-img.png" });
            }
        }}>
            <img style={{ height: "2.5em", width: "2.5em" }} src={playBtn.path} alt="play-video" />
        </div>
        <div className="video-title" onClick={() => refContainer.current.appendChild(renderer.domElement)}>{t(video.title) || t("No video")}</div>
    </div>);
}

export const setup = () => {

    const dimensions = { width: 0.9 * 800, height: 0.9 * 450 };
    const video = { title: "Canal inspection", path: "./videos/Poznan_short.mp4" };
    const scene = new Three.Scene();
    const perspectiveCam = new Three.PerspectiveCamera(75, dimensions.width / dimensions.height, 1, 100);
    const renderer = new Three.WebGLRenderer();
    const sphere = new Three.SphereGeometry(15, 128, 64);
    const videoEl = document.createElement("video");
    videoEl.src = video.path;
    //refContainer.current && refContainer.current.appendChild(renderer.domElement);
    renderer.setSize(dimensions.width, dimensions.height);
    videoEl.id = "video-conte";
    videoEl.crossOrigin = "anonymous";
    const texture = new Three.VideoTexture(videoEl);
    const material = new Three.MeshBasicMaterial({ map: texture });
    material.side = Three.BackSide;

    const mesh = new Three.Mesh(sphere, material);

    scene.add(mesh);
    renderer.setAnimationLoop(() => renderer.render(scene, perspectiveCam));
    // zoom in / out     
    const clamp = (v, min, max) => Math.max(min, Math.min(v, max));
    renderer.domElement.addEventListener('wheel', e => {
        e.preventDefault();
        if (e.ctrlKey) {
            perspectiveCam.fov = clamp(perspectiveCam.fov + e.deltaY, 10, 120);
            // need to call this function after changing most of properties in PerspectiveCamera
            perspectiveCam.updateProjectionMatrix();
        }
    }, { passive: false });

    // zooming on mobile

    if (!window.DOMMatrix) {
        if (window.WebKitCSSMatrix) {
            window.DOMMatrix = window.WebKitCSSMatrix;
        } else {
            throw new Error("Couldn't find a DOM Matrix implementation");
        }
    }

    // rotate camera
    let mouseDown = false;
    renderer.domElement.addEventListener('mousedown', e => {
        if (e.button === 0) mouseDown = true;
    });

    // renderer.domElement.addEventListener('click', e => {
    //     if (videoEl.paused) {
    //         videoEl.play();
    //     } else {
    //         videoEl.pause();
    //     }
    // });

    window.addEventListener('mouseup', e => {
        if (e.button === 0) mouseDown = false;
    });

    window.addEventListener('mousemove', e => {
        if (!mouseDown) return;

        const { movementX, movementY } = e;

        // rotateX: rotate vertically since x-axis is horizontal
        const rotateX = movementY / 100;
        const rotateY = movementX / 100;

        perspectiveCam.rotateX(rotateX);
        perspectiveCam.rotateY(rotateY);
    });

    return { video, videoEl, renderer };
}

export default Drones;