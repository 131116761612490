import { useTranslation } from "react-i18next";
import "./Main.css";

const Main = () => {

    const { t } = useTranslation();
    const welcomeText = t("Welcome text");
    const ourJobs = t("Our jobs").toUpperCase();
    const ourProjects = t("Our projects").toUpperCase();

    return (<div id="mainContent">
        <div id="welcome-ad">
            <img id="ad-bkgd" src="" alt="" />
            <h3>Professional Engineering Consulting</h3>
            <h1 style={{ color: "#fff", fontSize: "2.5em" }}>{welcomeText}</h1>
            <div id="welcome-btns">
                <div id="welcome-btn1"><span style={{ width: "102px" }}>{ourJobs}</span><img src="./right_arrow.png" alt="" /></div>
                <div id="welcome-btn2"><span style={{ width: "144px" }}>{ourProjects}</span><img src="./right_arrow.png" alt="" /></div>
            </div>
        </div>

        <div id="welcome-intro">
            {/* <img id="intro-bkgd" style={{ paddingTop: `calc(10px * var(--scroll-progress))` }} src="./images/who-we-are.jpg" alt="" /> */}
            <div id="introduction-text">
                <h1>{t("Who we are")}</h1>
                <div>{t("SABosca Intro")}</div>
            </div>
        </div>

    </div>);
}

export default Main;