import { useRouteError } from 'react-router-dom';
import './ErrorPage.css';
import { useTranslation } from "react-i18next";
import Header from "../Header";

const ErrorPage = ({ header }) => {

    const { t } = useTranslation();
    const message = { title: t("Error"), main: t("Error Main") };
    const error = useRouteError();

    console.log(error);

    return (<div id="error-page">
        {header && <Header />}
        <div id="error-content">
            <h2>{message.title + " " + error.status}</h2>
            <p>{message.main}</p>
            <p id="error-status">{error.statusText || error.message}</p>
        </div>
    </div>);
}

export default ErrorPage;